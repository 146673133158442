const pesquisa = {
    methods: {
        pesquisa(params) {
            let filtros = {}
            if (params.tipo_operacao_desc) {
                filtros.tipo_operacao_desc = params.tipo_operacao_desc
            }
            if (params.regiao) {
                filtros.regiao = params.regiao.map(r => r.public_id).toString()
            }
            if (params.fornecedor_cnpj) {
                filtros.fornecedor_cnpj = params.fornecedor_cnpj
            }
            if (params.produto_desc) {
                filtros.produto_desc = params.produto_desc
            }
            if (params.sub_produto_desc) {
                filtros.sub_produto_desc = params.sub_produto_desc
            }
            if (params.terminal_cnpj) {
                filtros.terminal_cnpj = params.terminal_cnpj
            }
            if (params.data_inicio) {
                filtros.data_inicio = params.data_inicio
            }
            if (params.data_fim) {
                filtros.data_fim = params.data_fim
            }
            if (params.tipo_frete) {
                filtros.tipo_frete = params.tipo_frete.id
            }
            if (params.regiao_desc) {
                filtros.regiao_desc = params.regiao_desc
            }
            if (params.cliente_cnpj) {
                filtros.cliente_cnpj = params.cliente_cnpj
            }
            if (params.representante_cpf) {
                filtros.representante_cpf = params.representante_cpf
            }
            if (params.transportadora) {
                filtros.transportadora = params.transportadora.public_id
            }
            if (params.tipo ? params.tipo == 'ton' : false) {
                filtros.qt_peso = 1
            }
            if (params.tipo ? params.tipo == 'qtd' : false) {
                filtros.qt_caminhoes = 1
            }
            return filtros
        }
    }
}
export { pesquisa }