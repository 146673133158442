import { mapGetters, mapState } from 'vuex'

import { LOCAL_STORAGE_ID } from "@/utils/relatorioCotasRegiao"

export const computed = {
  ...mapState('fornecedores', ['fornecedores']),
  ...mapState('clientes', ['clientes']),
  ...mapState('transportadoras', ['transportadoras']),
  ...mapGetters('contratos', ['terminais']),
  ...mapState('patio', ['regioes', 'representantes']),
  ...mapState('motoristas', ['motoristas']),
  ...mapState('auth', ['user', 'empresaAtual', 'perfilAtual']),

  showData() {
    return this.params.data_inicio?.visible || this.params?.data_fim.visible
  },
  showFornecedor() {
    return this.params.fornecedor_cnpj?.visible || this.params.produto_desc?.visible || this.params.sub_produto_desc?.visible
  },
  showContratos() {
    return this.params.terminal_cnpj?.visible || this.params.cliente_cnpj?.visible || this.params.transportadora?.visible
  },
  showRegiao() {
    return this.params.regiao_desc?.visible || this.params.representante_cpf?.visible
  },
  showVeiculo() {
    return this.params.motorista?.visible || this.params.placa_cavalo?.visible || this.params.eixo?.visible
  },
  areSettingsEmpty() {
    return !(this.showData || this.showFornecedor || this.showContratos || this.showRegiao || this.showVeiculo)
  },
  selectedOptions() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_ID)) || []
  }
}