<template>
  <v-col>
    <validation-provider v-slot="{ errors }" :rules="rules" :name="name">
      <v-text-field
        v-model="internalValue"
        v-mask="mask"
        :label="label"
        :prepend-icon="icon"
        :error-messages="errors"
        @input="$emit('input', $event)"
      />
    </validation-provider>
  </v-col>
</template>

<script>
export default {
  name: 'RelatorioCotasRegiaoListFiltroModalItemInput',
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
    },
    mask: {
      type: String,
    },
    rules: {
      type: [Object, String],
    },
  },
  data() {
    return {
      internalValue: '',
    }
  },
}
</script>
