<template>
  <v-menu
    :close-on-content-click="false"
    ref="menu"
    v-model="menu"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="value"
        :label="propLabel"
        :error-messages="errors"
        prepend-icon="mdi-calendar"
        v-on="on"
        @input="$emit('input', $event)"
        v-mask="['##/##/#### ##:##']"
      />
    </template>
  </v-menu>
</template>

<script>
export default {
  name: 'ConferenciaListFiltroData',
  props: {
    value: {
      type: String,
      required: true,
    },
    propLabel: {
      type: String,
      required: true,
    },
    errors: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      menu: '',
    }
  },
}
</script>