<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col>
        <relatorio-cotas-regiao-list-filtro @search="handleGetProgramacoes" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <cotas-programacao-fornecedor ref="cotas" v-bind:params="params" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

import RelatorioCotasRegiaoListFiltro from '@/components/patio/relatorioCotasRegiao/RelatorioCotasRegiaoListFiltro.vue'
import CotasProgramacaoFornecedor from '@/components/patio/relatorioCotasRegiao/Cotas.vue'

import { data } from './_data'

export default {
  name: 'RelatorioCotasRegiao',
  provide() {
    return {
      visualizarComprovante: this.visualizarComprovante,
      visualizarComprovanteAgendamento: this.visualizarComprovanteAgendamento,
      visualizarComprovanteLaudo: this.visualizarComprovanteLaudo,
      fecharComprovanteAgendamento: this.fecharComprovanteAgendamento,
    }
  },
  components: {
    RelatorioCotasRegiaoListFiltro,
    CotasProgramacaoFornecedor,
  },
  data,
  computed: {
    ...mapState('auth', ['empresaAtual']),
    ...mapState('relatorioCotasRegiao', ['params']),
  },
  methods: {
    async handleGetProgramacoes() {
      await this.$refs.cotas.atualizarEstrutura(this.params)
    },
  },
}
</script>
